import React from "react"
import styles from "./Cta.module.scss"
import Button from "./Button"
import Fade from "react-reveal/Fade"
import PropTypes from 'prop-types';

const Cta = ({ small, pb }) => {
  return (
    <div
      className={`${styles.cta} ${small ? styles.small : ""} ${pb ? styles.pb : ""}`}
    >
      <Fade bottom duration={700}>
        <div className={styles.ctaTitle}>Máte zájem o naše služby?</div>
        <div className={styles.ctaDesc}>Napište nám nebo zavolejte. Vše s Vámi rádi nezávazně probereme a v případě zájmu se domluvíme na další spolupráci.</div>
      </Fade>
      <Fade bottom duration={700}>
        <Button type="main" text="Kontaktaktuje nás" link="/kontakt" className="white" />
      </Fade>
    </div>
  )
}

export default Cta

Cta.propTypes = {
  small: PropTypes.bool,
  pb: PropTypes.bool,
}