import { graphql, useStaticQuery } from "gatsby"
import Project from "./Project"
import styles from "./Projects.module.scss"
import React, { useRef, useEffect } from "react"
import Filtering from "./Filtering"
import { navigate } from "gatsby-link"

const getProjects = graphql`
  query {
    allContentfulVyrobky(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          createdAt(formatString: "LLL")
          projectName
          id
          shortDesc
          projectDesc {
            json
          }
          projectType
          mainImg {
            title
            description
            fluid(maxWidth: 350, quality: 50) {
              ...GatsbyContentfulFluid
            }
          }
          slug
        }
      }
    }
  }
`

const Projects = () => {
  const allProjects = useStaticQuery(getProjects).allContentfulVyrobky.edges
  let filteredProjects = []
  const filterRef = useRef(null)
  const isFirstRun = useRef(true)

  // get all types
  const typesAll = allProjects.map(edge => edge.node.projectType)
  // sort by alphabet
  const typesSorted = typesAll.sort((a, b) => a.localeCompare(b))
  // set unique types
  const types = [...new Set(typesSorted)]

  // url query params
  const search = typeof window !== "undefined" ? window.location.search : ""
  const params = new URLSearchParams(search)
  const type = params.get("kategorie")

  // scroll to view when category is changed
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }
    if (filterRef.current) {
      filterRef.current.scrollIntoView()
    }
  }, [type])

  // get index of current type for react-select
  const indexOfType = types.indexOf(type)

  // filter projects based on query
  if (type === "Zobrazit vše" || !type) {
    filteredProjects = allProjects
  } else {
    const newProjects = allProjects.filter(project => {
      return project.node.projectType === type
    })
    filteredProjects = newProjects
  }

  // set query
  const changeCategory = type => {
    navigate(`?kategorie=${type}`)
  }

  return (
    <section className={`page-section ${styles.section}`}>
      <div className="container" ref={filterRef}>
        <p>
          V naší dílně dokážeme vyrobit téměř cokoliv. Od bran, plotů, zábradlí,
          schodišť až po krby a grily. Máme za sebou stovky projektů a díky tomu
          jsme schopni nabídnout bohaté zkušenosti, které promítneme do
          maximální kvality výrobku.
        </p>
        <div className={styles.filter}>
          <Filtering
            changeCategory={changeCategory}
            types={types}
            active={indexOfType}
            type={type}
          />
        </div>
        <div className={styles.projects}>
          {filteredProjects.map(project => (
            <Project key={project.node.id} project={project} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Projects
