import Select from "react-select"
import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types';

const Filtering = ({ changeCategory, types, type }) => {
  const options = [{ value: "Zobrazit vše", label: "Zobrazit vše" }]
  types.forEach(type => {
    options.push({ value: type, label: type })
  })

  const [value, setValue] = useState(null)

  useEffect(() => {
    if (type) {
      const index = types.indexOf(type)
      setValue(options[index + 1])
    } else if (type === "Zobrazit vše") {
      setValue(options[0])
    } else {
      setValue(null)
    }
  }, [type])

  const customStyles = {
    container: provided => ({
      ...provided,
      border: "1px solid transparent",
      transition: "all .2s",
      "&:hover": {
        border: "1px solid #cda45e",
      },
      borderRadius: "5px",
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #3b3834",
      color: "##e7e7e7",
      backgroundColor: state.isSelected ? "#cda45e" : "#2b2825",
      "&:hover": {
        backgroundColor: "#cda45e",
      },
      cursor: "pointer",
    }),
    control: provided => ({
      ...provided,
      backgroundColor: "#181614",
      color: "##e7e7e7",
      border: "none",
      padding: ".5rem 1rem",
      boxShadow: `none`,
      cursor: "pointer",
    }),
    placeholder: provided => ({
      ...provided,
      color: "#e7e7e7",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: "#181614",
      margin: "0",
    }),
    singleValue: provided => ({
      ...provided,
      color: "##e7e7e7",
    }),
    input: provided => ({
      ...provided,
      color: "##e7e7e7",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: "#e7e7e7",
      "&:hover": {
        color: "#e7e7e7",
      },
    }),
  }

  const change = v => {
    changeCategory(v.value)
  }

  return (
    <Select
      options={options}
      onChange={change}
      styles={customStyles}
      placeholder={"Vyberte kategorii"}
      value={value && value}
      isSearchable={false}
    />
  )
}

export default Filtering

Filtering.propTypes = {
  changeCategory: PropTypes.func,
  types: PropTypes.array,
  type: PropTypes.string
}
