import React from "react"
import Layout from "../components/layout/Layout"
import SecondaryHeader from "../components/sections/SecondaryHeader"
import Projects from "../components/projects/Projects"
import SEO from "../components/SEO"
import seotext from "../constants/seotext"
import Cta from "../components/Cta"
import { graphql, useStaticQuery } from "gatsby"

export default ({ pageContext }) => {

  const image = useStaticQuery(graphql`
    query {
      reference: contentfulAsset(title: { eq: "CANR8274" }) {
        fluid(maxWidth: 1600, quality: 50) {
          ...GatsbyContentfulFluid
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Reference" description={seotext.reference} />
      <SecondaryHeader
        pageContext={pageContext}
        disable={["/reference"]}
        heading="Naše"
        bold="Reference"
        image={image.reference.fluid}
      />
      <Projects />
      <Cta small />
    </Layout>
  )
}
